var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Panel",
    {
      staticClass: "killfeed-container",
      attrs: {
        expandable: _vm.expandable,
        expanded: _vm.expanded,
        icon: _vm.$options.components.IconTarget,
        "icon-color": "#e03a47"
      },
      on: { "update:expanded": _vm.$listeners["update:expanded"] },
      scopedSlots: _vm._u([
        {
          key: "header-text",
          fn: function() {
            return [
              _c("span", { staticClass: "killfeed-label" }, [
                _c("span", { staticClass: "first" }, [_vm._v("Kill")]),
                _c("span", { staticClass: "second" }, [_vm._v("Feed")])
              ])
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "killfeed" },
        _vm._l(_vm.events, function(event) {
          return _c(_vm.getComponentForEventType(event.type), {
            key: event.id,
            tag: "component",
            attrs: {
              agents: _vm.agents,
              event: event,
              future: _vm.isFuture(event),
              "match-players": _vm.matchPlayers,
              players: _vm.players,
              rounds: _vm.rounds,
              "round-teams": _vm.roundTeams,
              weapons: _vm.weapons
            },
            nativeOn: {
              click: function($event) {
                return _vm.$emit("select", event)
              }
            }
          })
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }