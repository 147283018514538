import { render, staticRenderFns } from "./KillfeedItemKill.vue?vue&type=template&id=70900b73&scoped=true&"
import script from "./KillfeedItemKill.vue?vue&type=script&lang=js&"
export * from "./KillfeedItemKill.vue?vue&type=script&lang=js&"
import style0 from "./KillfeedItemKill.vue?vue&type=style&index=0&id=70900b73&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70900b73",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2384520030/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('70900b73')) {
      api.createRecord('70900b73', component.options)
    } else {
      api.reload('70900b73', component.options)
    }
    module.hot.accept("./KillfeedItemKill.vue?vue&type=template&id=70900b73&scoped=true&", function () {
      api.rerender('70900b73', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Map/components/KillfeedItemKill.vue"
export default component.exports