var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KillfeedItem",
    {
      staticClass: "utility",
      class: [_vm.grid, _vm.role],
      attrs: { event: _vm.event }
    },
    [
      _c("div", { staticClass: "player" }, [
        _c("img", {
          staticClass: "agent-icon",
          attrs: { alt: _vm.agent.name, src: _vm.agent.display_icon_small_url }
        }),
        _c("div", { staticClass: "player-name" }, [
          _vm._v(" " + _vm._s(_vm.player.name) + " ")
        ]),
        _c("div", { staticClass: "item-container" }, [
          _c("img", {
            staticClass: "item-icon",
            class: _vm.event.sidebar ? "item-icon-sidebar" : "",
            attrs: {
              src: _vm.ability.displayIcon,
              title: _vm.ability.displayName
            }
          })
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }