var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "video-player-container" },
    [
      _c("video-player", {
        key: _vm.url,
        ref: "videoPlayer",
        staticClass:
          "video-player-box vjs-big-play-centered video-player-container",
        class: { controlBar: _vm.controlBar },
        attrs: {
          "cross-origin": "anonymous",
          options: _vm.player_options,
          playsinline: true
        },
        on: {
          canplay: _vm.onPlayerCanPlay,
          pause: function($event) {
            return _vm.$emit("update:playing", false)
          },
          playing: function($event) {
            return _vm.$emit("update:playing", true)
          },
          timeupdate: _vm.timeupdate
        }
      }),
      _vm.clock
        ? _c("div", { staticClass: "clock", class: "clock--" + _vm.clock }, [
            _c("span", [_vm._v(_vm._s(_vm.currentTimeFormatted))])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }