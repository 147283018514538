var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KillfeedItem",
    {
      staticClass: "kill",
      class: [_vm.killerRole],
      attrs: { event: _vm.event, future: _vm.future }
    },
    [
      _c("div", { staticClass: "pad-right", class: [_vm.victimRole] }, [
        _vm.event.is_trade
          ? _c(
              "span",
              {
                directives: [
                  {
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover.noninteractive",
                    modifiers: { hover: true, noninteractive: true }
                  }
                ],
                staticClass: "trade-icon",
                attrs: { title: "Trade" }
              },
              [_vm._v("⇄")]
            )
          : _vm._e(),
        _c("div", { staticClass: "player-name victim" }, [
          _vm._v(" " + _vm._s(_vm.victimName) + " ")
        ]),
        _c("img", {
          staticClass: "agent-icon",
          attrs: {
            alt: _vm.victimAgent.name,
            src: _vm.victimAgent.display_icon_small_url
          }
        })
      ]),
      _c("div", { staticClass: "pad-left" }, [
        _c("img", {
          staticClass: "agent-icon",
          class: { dead: _vm.event.post_mortem },
          attrs: {
            alt: _vm.killerAgent.name,
            src: _vm.killerAgent.display_icon_small_url
          }
        }),
        _c("div", { staticClass: "player-name killer" }, [
          _vm._v(" " + _vm._s(_vm.killerName) + " ")
        ]),
        _vm.event.finishing_damage.damage_type === "Ability"
          ? _c("div", { staticClass: "item-container ability" }, [
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  alt: _vm.ability.displayName,
                  src: _vm.ability.displayIcon
                }
              })
            ])
          : _vm._e(),
        _vm.event.finishing_damage.damage_type === "Bomb"
          ? _c("div", { staticClass: "item-container bomb" }, [
              _c(
                "div",
                {
                  staticClass: "item-name",
                  attrs: { "data-item": _vm.event.finishing_damage.damage_item }
                },
                [_vm._v("Bomb")]
              )
            ])
          : _vm._e(),
        _vm.event.finishing_damage.damage_type === "Melee"
          ? _c("div", { staticClass: "item-container melee" }, [
              _c(
                "div",
                {
                  staticClass: "item-name",
                  attrs: { "data-item": _vm.event.finishing_damage.damage_item }
                },
                [_vm._v("Melee")]
              )
            ])
          : _vm._e(),
        _vm.event.finishing_damage.damage_type === "Weapon"
          ? _c("div", { staticClass: "item-container weapon" }, [
              _c("img", {
                staticClass: "item-icon",
                attrs: {
                  alt: _vm.weapon.name,
                  src: _vm.weapon.kill_stream_icon_url
                }
              })
            ])
          : _vm._e(),
        _vm.event.finishing_damage.damage_type === "Fall"
          ? _c("div", { staticClass: "item-container weapon" }, [
              _vm._v("Fall")
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }