var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svg", { attrs: { viewBox: "0 0 24 24", fill: "currentColor" } }, [
    _c("path", {
      attrs: {
        "fill-rule": "evenodd",
        d:
          "m13 2v2.1q1.3 0.1 2.5 0.7 1.2 0.6 2.2 1.5 0.9 1 1.5 2.2 0.6 1.2 0.7 2.5h2.1v2h-2.1q-0.1 1.3-0.7 2.5-0.6 1.2-1.5 2.2-1 0.9-2.2 1.5-1.2 0.6-2.5 0.7v2.1h-2v-2.1q-1.3-0.1-2.5-0.7-1.2-0.6-2.2-1.5-0.9-1-1.5-2.2-0.6-1.2-0.7-2.5h-2.1v-2h2.1q0.1-1.3 0.7-2.5 0.6-1.2 1.5-2.2 1-0.9 2.2-1.5 1.2-0.6 2.5-0.7v-2.1m-4.9 9h1.9v2h-1.9c0.4 2.5 2.4 4.5 4.9 4.9v-1.9h2v1.9c2.5-0.4 4.5-2.4 4.9-4.9h-1.9v-2h1.9c-0.4-2.5-2.4-4.5-4.9-4.9v1.9h-2v-1.9c-2.5 0.4-4.5 2.4-4.9 4.9zm6.3 0.1q0.2 0.1 0.3 0.2 0.1 0.1 0.2 0.3 0.1 0.2 0.1 0.4 0 0.2-0.1 0.4-0.1 0.2-0.2 0.3-0.1 0.1-0.3 0.2-0.2 0.1-0.4 0.1-0.2 0-0.4-0.1-0.2-0.1-0.3-0.2-0.1-0.1-0.2-0.3-0.1-0.2-0.1-0.4 0-0.2 0.1-0.4 0.1-0.2 0.2-0.3 0.1-0.1 0.3-0.2 0.2-0.1 0.4-0.1 0.2 0 0.4 0.1z"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }