import { render, staticRenderFns } from "./SubmitMatchVod.vue?vue&type=template&id=923bc502&"
import script from "./SubmitMatchVod.vue?vue&type=script&lang=js&"
export * from "./SubmitMatchVod.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src2384520030/src/web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('923bc502')) {
      api.createRecord('923bc502', component.options)
    } else {
      api.reload('923bc502', component.options)
    }
    module.hot.accept("./SubmitMatchVod.vue?vue&type=template&id=923bc502&", function () {
      api.rerender('923bc502', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Match.old/SubmitMatchVod.vue"
export default component.exports