var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FormController", {
    staticClass: "form",
    attrs: { submit: _vm.submit, "data-form-type": "other" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _c(
              "b-form-group",
              {
                staticClass: "flex-fill",
                attrs: { label: "Twitch/Youtube url", "label-for": "vod_url" }
              },
              [
                _c("b-form-input", {
                  class: {
                    "is-valid": _vm.$v.form.vod_url.$model !== "",
                    "is-invalid": _vm.$v.form.vod_url.$error
                  },
                  attrs: { id: "vod_url", type: "text" },
                  model: {
                    value: _vm.$v.form.vod_url.$model,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.$v.form.vod_url,
                        "$model",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "$v.form.vod_url.$model"
                  }
                }),
                _vm.$v.form.vod_url.$error && !_vm.$v.form.vod_url.required
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v("Field is required")
                    ])
                  : _vm._e(),
                _vm.$v.form.vod_url.$error && !_vm.$v.form.vod_url.minLength
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        " Vod url must have at least " +
                          _vm._s(_vm.$v.form.vod_url.$params.minLength.min) +
                          " letters. "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c(
                  "b-form-group",
                  {
                    staticClass: "flex-fill mr-4",
                    attrs: {
                      label: "Vod start time (Format: hh:mm:ss)",
                      "label-for": "start_time"
                    }
                  },
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "##:##:##",
                          expression: "'##:##:##'"
                        },
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.focus.noninteractive",
                          modifiers: { focus: true, noninteractive: true }
                        }
                      ],
                      attrs: {
                        id: "start_time",
                        type: "text",
                        title:
                          "Enter the time 5 seconds before the first round."
                      },
                      model: {
                        value: _vm.form.start_time,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "start_time",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.start_time"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "b-form-group",
                  {
                    staticClass: "flex-fill",
                    attrs: {
                      label: "Vod end time (Format: hh:mm:ss)",
                      "label-for": "end_time"
                    }
                  },
                  [
                    _c("b-form-input", {
                      directives: [
                        {
                          name: "mask",
                          rawName: "v-mask",
                          value: "##:##:##",
                          expression: "'##:##:##'"
                        },
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.focus.noninteractive",
                          modifiers: { focus: true, noninteractive: true }
                        }
                      ],
                      attrs: {
                        id: "end_time",
                        type: "text",
                        title:
                          "Enter the time once you see team ATK/DEF won/lost."
                      },
                      model: {
                        value: _vm.form.end_time,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.form,
                            "end_time",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "form.end_time"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-button",
              {
                attrs: {
                  type: "submit",
                  variant: "warning",
                  disabled: _vm.$v.form.$invalid
                }
              },
              [_vm._v(" Submit ")]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }