<template>
  <div class="video-player-container">
    <video-player
      :key="url"
      cross-origin="anonymous"
      class="video-player-box vjs-big-play-centered video-player-container"
      :class="{ controlBar }"
      ref="videoPlayer"
      :options="player_options"
      :playsinline="true"
      @canplay="onPlayerCanPlay"
      @pause="$emit('update:playing', false)"
      @playing="$emit('update:playing', true)"
      @timeupdate="timeupdate"
    />
    <div v-if="clock" class="clock" :class="`clock--${clock}`">
      <span>{{ currentTimeFormatted }}</span>
    </div>
  </div>
</template>

<script>
import px from 'vue-types'
import { videoPlayer } from 'vue-video-player'

import { pxNullable } from '@/components/Map/types'

import { BOOKMARK_THUMBNAIL_SIZE } from '../../constants.js'
import formatTime from '../Map/utils/formatTime.js'

import 'video.js/dist/video-js.css'

export default {
  name: 'VodReplay',

  components: {
    videoPlayer,
  },

  props: {
    controlBar: px.bool,
    clock: pxNullable(px.oneOf(['top'])).def(null),
    currentTime: px.number.def(0),
    playing: px.bool.def(false),
    playbackRate: px.number.def(1.0),
    url: px.string,
    muteVod: px.bool.def(true),
  },

  computed: {
    currentTimeFormatted() {
      return formatTime(100000 - this.currentTime)
    },

    player_options() {
      return {
        // videojs options
        language: 'en',
        playbackRates: [1.0, 3.0, 4.0],
        autoplay: false,
        height: 800,
        responsive: true,
        sources: [
          {
            type: 'video/mp4',
            src: this.url,
          },
        ],
        // poster: `/${this.$route.params.round}.png`,
      }
    },
  },

  watch: {
    currentTime(val) {
      if (this.$refs.videoPlayer?.player && val !== this.lastEmittedTime) {
        this.$refs.videoPlayer?.player.currentTime(val / 1000)
      }
    },
    playing(val) {
      if (this.$refs.videoPlayer?.player) {
        if (val) {
          this.$refs.videoPlayer?.player.play()
        } else {
          this.$refs.videoPlayer?.player.pause()
        }
      }
    },
    playbackRate(val) {
      this.$refs.videoPlayer?.player.playbackRate(val)
    },
    muteVod(val) {
      this.$refs.videoPlayer?.player.muted(val)
    },
  },

  mounted() {
    this.$emit('update:supportedPlaybackRates', [1, 3, 4])
    this.$refs.videoPlayer?.player.muted(true)
  },

  methods: {
    onPlayerCanPlay() {
      if (this.playing) {
        this.$refs.videoPlayer?.player.play()
      }
      if (Math.abs(this.$refs.videoPlayer?.player.currentTime() * 1000 - this.currentTime) > 100) {
        this.$refs.videoPlayer?.player.currentTime(this.currentTime / 1000)
      }
      this.$refs.videoPlayer?.player.playbackRate(this.playbackRate)
    },
    takeScreenshot() {
      if (!this.$refs.videoPlayer?.$refs.video) {
        return null
      }

      const canvas = document.createElement('canvas')
      canvas.width = BOOKMARK_THUMBNAIL_SIZE
      canvas.height = BOOKMARK_THUMBNAIL_SIZE
      const ctx = canvas.getContext('2d')
      ctx.drawImage(this.$refs.videoPlayer.$refs.video, 0, 0, canvas.width, canvas.height)

      //convert to desired file format
      try {
        return canvas.toDataURL()
      } catch (e) {
        console.warn(e)
        return null
      }
    },
    timeupdate() {
      this.lastEmittedTime = this.$refs.videoPlayer?.player.currentTime() * 1000
      this.$emit('update:currentTime', this.lastEmittedTime)
    },
  },
}
</script>

<style lang="scss" scoped>
.video-player,
.video-player ::v-deep .video-js {
  width: 100% !important;
}

// fit the video player in vertically available space
.video-player-container {
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
  max-height: 100%;

  .clock {
    position: absolute;
    pointer-events: none;

    &--top {
      top: 0;
      left: 0;
      right: 0;
      text-align: center;
    }

    span {
      background: rgb(0 0 0 / 50%);
      padding: 0.5em 1em;
      border-bottom-left-radius: 0.5em;
      border-bottom-right-radius: 0.5em;
      opacity: 0.666;
    }
  }
}

.video-player {
  &:not(.controlBar) ::v-deep .vjs-control-bar {
    display: none;
  }
}
</style>
