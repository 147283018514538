var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "KillfeedItem",
    {
      class: [_vm.defuserGrid, _vm.defuserRole],
      attrs: { event: _vm.event, future: _vm.future }
    },
    [_c("IconDefuse", { staticClass: "item-icon" })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }