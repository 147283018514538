var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "v3p-container", class: [_vm.variant] }, [
    _vm.$slots.header ||
    _vm.icon ||
    _vm.$slots["header-text"] ||
    _vm.title ||
    _vm.expandable
      ? _c(
          "div",
          {
            staticClass: "v3p-header-container",
            class: { sticky: _vm.variant === "main" }
          },
          [
            _vm._t("header", function() {
              return [
                _c(
                  _vm.expandable ? _vm.$options.components.V3Button : "div",
                  {
                    tag: "component",
                    staticClass: "v3p-header",
                    class: { expanded: _vm.expanded },
                    on: { click: _vm.onTitleClick }
                  },
                  [
                    _vm._t(
                      "header-content",
                      function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "v3p-header-content" },
                            [
                              _vm._t("header-icon", function() {
                                return [
                                  _vm.icon
                                    ? _c(_vm.icon, {
                                        tag: "component",
                                        staticClass: "v3p-header-icon",
                                        style: _vm.iconStyles
                                      })
                                    : _vm._e()
                                ]
                              }),
                              _vm._t("header-text", function() {
                                return [
                                  _c(
                                    "span",
                                    { class: { "pad-left": !_vm.icon } },
                                    [_vm._v(_vm._s(_vm.title))]
                                  )
                                ]
                              })
                            ],
                            2
                          )
                        ]
                      },
                      null,
                      { expanded: _vm.expanded }
                    ),
                    _vm.expandable
                      ? _c(
                          "div",
                          {
                            staticClass: "v3p-expand",
                            class: { expanded: _vm.expanded },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.toggleExpanded.apply(null, arguments)
                              }
                            }
                          },
                          [
                            _vm._t(
                              "expand",
                              function() {
                                return [
                                  _vm.variant === "main"
                                    ? [_vm._v(_vm._s(_vm.expanded ? "-" : "+"))]
                                    : _vm._e(),
                                  _vm.variant === "secondary"
                                    ? [
                                        _c("IconArrowBack", {
                                          staticClass: "v3p-expand-icon",
                                          class: { expanded: _vm.expanded }
                                        })
                                      ]
                                    : _vm._e()
                                ]
                              },
                              null,
                              { expanded: _vm.expanded }
                            )
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  2
                )
              ]
            })
          ],
          2
        )
      : _vm._e(),
    _c("div", { staticClass: "v3p-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }